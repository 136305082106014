.sheetsw-rightBannerWrapper {
    width: 300px;
    height: 100%;
    margin-left: auto;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    overflow-y: auto;
    position: sticky;
    bottom: 0;
    top: 0;
  }
  
  .sheetsw-logoTransparentPNG {
    object-fit: contain;
    width: 200px;
  }
  
  .sheetsw-bannerHeader {
    font-size: 25px; 
    font-family: 'raleway-bold', sans-serif; 
  }
  
  .sheetsw-multiDevicePNG {
    object-fit: contain;
    width: 250px;
  }
  
  .sheetsw-bannerFeaturesContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-items: center;
    height: 45%;
    align-items: center;
  }
  .sheetsw-timeTrackingPNG {
    object-fit: contain;
    width: 40px;
    margin-left: 20px;
  }
  
  .sheetsw-realTimeUpdatesPNG {
    object-fit: contain;
    width: 40px;
    margin-left: 20px;
  
  }
  .sheetsw-analyticsPNG {
    object-fit: contain;
    width: 40px;
    margin-left: 20px;
  
  }
  .sheetsw-costSavingsPNG {
    object-fit: contain;
    width: 40px;
    margin-left: 20px;
  
  }
  
  .sheetsw-bannerText {
    font-size: 14px;
    font-family: 'raleway-bold', sans-serif;
    align-self: center;
    justify-self: start;
    text-align: left;
    margin-left: 10px;
  }
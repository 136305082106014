
#timeline-table .ant-table-thead > tr > th {
  height: 32.5px; 
  line-height: 32.5px; 
  white-space: noWrap;
}

#timeline-table .ant-table-thead .ant-table-cell {
  background-color: white;
  border-top: solid 1px #e8e9ea;
} 

#timeline-table tbody.ant-table-tbody > tr:nth-child(odd) {
  background-color: #fff; 
}


#timeline-table tbody.ant-table-tbody > tr:nth-child(even) {
  background-color: #f5f5f5; 
}

.custom-header {
  height: 100%;  
  background-color: #fff; 
}

#gantt-container > div {
  width: 100%;
}
#gantt-container > div ._CZjuD {
  overflow-x: scroll;
}
#gantt-container > div ._19jgW {
  display: none;
}
#gantt-container > div ._nI1Xw > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#gantt-container > div .gantt-task-row:nth-child(even) {
  background: #f5f5f5;
}
#gantt-container > div .gantt-task-row:nth-child(odd) {
  background: #fff;

}
#gantt-container ._3ZbQT {
  display: none;
}
#gantt-container ._3_ygE {
  display: none;
}
#gantt-container ._35nLX {
  background: #fafafa;
  border: none;
}
#gantt-container div div._3eULf div._CZjuD div._2B2zv svg g.grid g.gridBody g.today rect {
  width: 3px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#timeline-table::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#timeline-table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

/* Hide scrollbar for Chrome, Safari and Opera */
#gantt-container ._CZjuD::-webkit-scrollbar {
  display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
#gantt-container ._CZjuD {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.sheetsm-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  background: #ffdc73
}

.sheetsm-tab-container {
  display: flex;
  justify-content: space-around;
}

.sheetsm-tab {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #858576;
  color: white;
  text-align: center;
  line-height: 30px;
}

.tab:hover {
  cursor: pointer;
}

.active-tab {
  background: white;
  color: #858576;
}

.dayOfWeek{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.sheetsm-weeklyTotalSection {
  margin-left: 58%;
}


.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.sheetsmInput {
    width: 200px;
    border-radius: 20px;
    margin-bottom: 20px;
  }


.sheetsm-buttonGroup {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}




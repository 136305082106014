
.sheetsw {
  background: #ffdc73;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  min-height: 850px;
}
.sheetsw-leftSideWrapper {
  padding: 10px 40px;
  flex-grow: 1;
  height: 100%;
}

.sheetsw-container {
  display: flex;
  flex-direction: column;
}

.sheetsw-headingWrapper {
  display: flex;
  margin-bottom: 30px;
}

.logoRoundedPNG {
  object-fit: contain;
  width: 180px;
  height: 100px;
}

.heading {
  font-size: 48px;
  color: #BF381D;
  height: 70px;
  font-family: 'raleway-bold', sans-serif;
  margin-top: auto;
  margin-bottom: 5px;
  margin-left: 10px;
}
.sheetswInputAndTotalWrapper {
  display: flex;
  flex-direction: row;
}

.sheetswInput {
  width: 200px;
  border-radius: 20px;
}

.sheetswInputEmployeeID {
  margin-left: 20px;
}

.weeklyTotalSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  font-size: 18px;
  font-family: 'raleway-bold', sans-serif;
}

.weeklyTotal-text {
  font-size: inherit;
  font-family: inherit;

}

.weeklyTotal-number {
  /* styling for weekly total number */
  font-size: inherit;
  margin-left: 30px;
}

.sheetsw-tableWrapper {
  border-radius: 20px;
  overflow: hidden;
}

.sheetsw-table {
  margin-top: 20px;
  font-family: 'roboto-regular', sans-serif;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 1500px;
}

.sheetsw-buttonGroup {
  margin-top: 30px;
}

.sheetsw-copyright {
  position: absolute;
  bottom: 5px;
}


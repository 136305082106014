/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.card-shadow {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.breadcrumb-link {
  padding: 3px;
  border-radius: 4px;
}
.breadcrumb-link:hover,
.breadcrumb-link:active {
  color: black !important;
  background-color: lightgray;
  opacity: 0.65;
}
.drawer-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: #e9e9e9 1px solid;
  padding: 10px 16px;
  background: white;
  text-align: right;
}
.date-range-picker-icon {
  font-size: 20px;
  margin-left: 0px !important;
  margin-top: 3px;
  color: black;
}
.tag-view {
  background-color: rgba(198, 198, 198, 0.15);
  color: #BF381D;
}
.listview-table-scroll {
  cursor: pointer;
}
.listview-table-scroll:hover {
  background-color: #F8F8F8;
}
.slider-download-button {
  position: absolute;
  top: 0;
  right: 40px;
  width: 56px;
  height: 54px;
  padding: 18px;
  margin: 0;
}
.custom-field-container {
  display: flex;
  flex: 1;
  position: relative;
}
.custom-field-container .ant-input-disabled.OnTraccrInput {
  background: #fff;
  color: #000;
  border: none;
  padding: 0;
  cursor: default;
}
.custom-field-container .ant-select-disabled .ant-select-selector {
  background: #fff !important;
  color: #000 !important;
  border: none;
  padding: 0;
  cursor: default !important;
}
.custom-field-container .ant-select-disabled.ant-select .ant-select-arrow {
  display: none;
}
#login {
  height: 100vh !important;
  align-content: center;
}
.login-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  overflow: hidden;
  background-color: #FFDC73;
  height: 100vh !important;
  align-content: center;
  transition: top 0.5s ease-in-out;
}
#userRow {
  padding-top: 25%;
}
.antd-input {
  width: 300px !important;
  align-content: center;
}
.Row {
  margin-bottom: 8px;
}
.login-form-button {
  background-color: #BF381D;
  border-color: #BF381D;
}
.login-form-button:hover,
.login-form-button:active,
.login-form-button:focus {
  background-color: #BF381DC8;
  border-color: #BF381DC8;
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover,
.ant-checkbox-checked .ant-checkbox-inner:focus,
.ant-checkbox-checked .ant-checkbox-inner:active,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #BF381D;
  border-color: #BF381D;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-checked::after {
  border-color: #BF381D;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login-form-link {
  color: #BF381D;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: inherit;
  opacity: 0.65;
}
.ant-form-item-explain {
  overflow-wrap: break-word;
  max-width: 300px;
}
.login-input {
  border-radius: 0px;
}
.login-card {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 30px;
  border: #C6C6C6 1px solid;
  padding: 20px 40px;
}

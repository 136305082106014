
.timePickerGroup {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.dailyTotal {
    margin-left: 60%;
    width: 120px;
}